<script>
	import { _ } from 'svelte-i18n';
	import { fly, slide } from 'svelte/transition';
	import { data, notification } from '../stores.js';
	import { putManaged, getManagedLogos, putManagedLogo, postLogo } from '../utils/api-calls.js';
	import { push as navigateTo } from 'svelte-spa-router';
	import InfoIcon from './icon/InfoIcon.svelte';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';
	import UploadIcon from './icon/UploadIcon.svelte';
	import {
		DEFAULT_MANAGED_LOGO,
		DEFAULT_PERSONAL_LOGO,
		RECOMMENDED_MANAGED_LOGO_SIZE
	} from '../constants.js';
	import ChooseManagedLogoButton from './button/ChooseManagedLogoButton.svelte';
	import * as sort from '../utils/sort.js';

	export let states = {};
	export let managedLogos = null;

	$: managedId = $data.chooseWhoManages?.id || $data.chooseManagedLogo?.id;

	let uploadedLogos = [];

	const defaultManagedName = 'default-org.png';

	let selectManagedLogoAjax = false;
	const selectManagedLogo = async () => {
		try {
			selectManagedLogoAjax = true;
			const res = await putManaged(managedId, {
				managedBy: 'org'
			});
			if (!res.success) throw new Error('Failed to update managed by');
			managedLogos = await getManagedLogos($data.chooseWhoManages.id);
			states.PICK_IMAGE = true;
		} catch (err) {
			console.error(err);
			$notification = {
				text: $_('Something went wrong. Please try again later.'),
				type: 'error'
			};
		} finally {
			selectManagedLogoAjax = false;
		}
	};

	let selectManagedSelfAjax = false;
	const selectManagedSelf = async () => {
		try {
			selectManagedSelfAjax = true;
			const res = await putManaged(managedId, {
				managedBy: 'self'
			});
			if (!res.success) throw new Error('Failed to update managed by');
			$data = {}; //data will be refetched at root
			return navigateTo('/');
		} catch (err) {
			console.error(err);
		} finally {
			selectManagedLogoAjax = false;
		}
	};

	let selectManagedOrgAjax = null;
	const selectManagedOrg = async (logo) => {
		try {
			selectManagedOrgAjax = logo.name;
			const res = await putManagedLogo(managedId, {
				name: logo.name,
				mimeType: logo.mimeType
			});
			if (!res.success) throw new Error('Failed to update managed by');
			$data = {}; //data will be refetched at root
			return navigateTo('/');
		} catch (err) {
			console.error(err);
		} finally {
			selectManagedOrgAjax = null;
		}
	};

	const acceptedLogoTypes = ['.png', '.gif', '.jpg', '.jpeg', '.webp', '.apng'];
	function handleLogoDrop(e) {
		e.preventDefault();
		if (!e.dataTransfer) return null;
		const fileName = e.dataTransfer.files[0].name;
		const fileExt = fileName.split('.').pop();
		if (!acceptedLogoTypes.includes(`.${fileExt}`)) {
			return ($notification = {
				text: `Please upload a valid image format (${acceptedLogoTypes.join(',')})`,
				type: 'error'
			});
		}
		handleLogoUpload(e.dataTransfer.files);
	}

	let logoUploadAjax = false;
	async function handleLogoUpload(e) {
		const file = e.target?.files?.[0] || e[0];
		if (!file) return;
		logoUploadAjax = true;
		const formDataObj = new FormData();
		formDataObj.append('file', file);
		try {
			const logo = await postLogo(managedId, formDataObj);
			logo.originalName = file.name;
			uploadedLogos = [...uploadedLogos, logo];
		} catch (err) {
			if (err.status === 413) {
				//TBD - Compress image client-side
				$notification = {
					text: $_('File size is too large. Please upload a smaller file'),
					type: 'error'
				};
			}
		} finally {
			logoUploadAjax = false;
			document.getElementById('logo-upload-input-ref').value = '';
		}
	}

	$: managed = $data.chooseManagedLogo || $data.chooseWhoManages;
</script>

{#if states.PICK_IMAGE}
	<div in:fly|local={{ y: 10 }}>
		<h1 class="text-lg">{$_('Select the logo to be used for')}</h1>
		<h1 data-test="domain" class="text-lg font-semibold px-4">{managed?.domain}</h1>
		<div class="my-6 px-indent space-y-5">
			{#if Array.isArray(managedLogos) && managedLogos.length}
				<div data-test="discovered-logos">
					<span class="text-sm font-medium">
						{#if managedLogos.length > 1}
							{$_('We found {count} logos', {
								values: { count: managedLogos.length }
							})}
						{:else}
							{$_('We found 1 logo')}
						{/if}
					</span>
					<div class="space-y-3 mt-1">
						{#each managedLogos.sort(sort.managedLogos) as logo}
							<ChooseManagedLogoButton
								loading={selectManagedOrgAjax === logo.name}
								disabled={selectManagedOrgAjax === logo.name}
								logo={logo.url}
								mimeType={logo.mimeType}
								fileName={logo.originalName}
								on:click={() => selectManagedOrg(logo)}
								size={{ width: logo.width, height: logo.height }}
							/>
						{/each}
					</div>
				</div>
			{/if}
			<div data-test="default-logo">
				<span class="text-sm font-medium">{$_('Default account logo')}</span>
				<div class="space-y-3 mt-1">
					<ChooseManagedLogoButton
						logo={DEFAULT_MANAGED_LOGO}
						loading={selectManagedOrgAjax === defaultManagedName}
						disabled={selectManagedOrgAjax === defaultManagedName}
						fileName={defaultManagedName}
						size={{ width: RECOMMENDED_MANAGED_LOGO_SIZE, height: RECOMMENDED_MANAGED_LOGO_SIZE }}
						on:click={() =>
							selectManagedOrg({
								name: defaultManagedName,
								mimeType: 'image/png'
							})}
					/>
				</div>
			</div>
			{#if uploadedLogos?.length}
				<ul data-test="uploaded-logos" transition:slide|local>
					<span class="text-sm font-medium">{$_('Uploaded from device')}</span>
					<div class="space-y-3 mt-1">
						{#each uploadedLogos.sort(sort.managedLogos) as logo}
							<li transition:slide|local>
								<ChooseManagedLogoButton
									logo={logo.url}
									loading={selectManagedOrgAjax === logo.name}
									disabled={selectManagedOrgAjax === logo.name}
									fileName={logo.originalName}
									mimeType={logo.mimeType}
									on:click={() => selectManagedOrg(logo)}
									size={{ width: logo.width, height: logo.height }}
								/>
							</li>
						{/each}
					</div>
				</ul>
			{/if}
			<div>
				<span class="text-sm font-medium">{$_('Upload from device')}</span>
				<button
					on:dragover={(e) => {
						e.preventDefault();
						e.target?.classList.add('border-2');
					}}
					on:dragenter={(e) => {
						e.preventDefault();
						e.target?.classList.add('border-2');
					}}
					on:dragleave={(e) => {
						e.preventDefault();
						e.target?.classList.remove('border-2');
					}}
					on:drop={(e) => {
						handleLogoDrop(e);
						e.target?.classList.remove('border-2');
					}}
					on:click={() => {
						document.getElementById('logo-upload-input-ref').click();
					}}
					class="mt-1 h-18 w-full flex items-center justify-center text-sm border border-dashed border-charcoal dark:border-[#d4d4d4] rounded-md space-x-2 hover:border-2"
					disabled={logoUploadAjax}
				>
					{#if logoUploadAjax}
						<SpinnerIcon css="h-6 w-6" />
					{:else}
						<div class="flex items-center space-x-2 pointer-events-none">
							<UploadIcon />
							<span>{$_('Click to upload or drag & drop')}</span>
						</div>
					{/if}
				</button>
				<input
					on:change={(e) => handleLogoUpload(e)}
					id="logo-upload-input-ref"
					accept={acceptedLogoTypes.join(', ')}
					type="file"
					name="logo"
					hidden
				/>
			</div>
		</div>
	</div>
{:else}
	{#if managed?.email}
		<h1 class="text-lg">{$_('You logged in with')}</h1>
		<h1 data-test="email" class="text-lg font-medium px-4">{managed?.email}</h1>
	{/if}

	<div class="mt-6">
		<h1 class="text-lg">{$_('We need to know more about')}</h1>
		<h1 data-test="domain" class="px-4 text-3xl font-medium text-center mt-7 mb-8">
			{managed?.domain}
		</h1>
	</div>

	<div class="flex flex-col space-y-3 my-6">
		<button
			on:click={selectManagedLogo}
			class="btn-background text-left px-4 h-18 text-lg inline-flex items-center"
			disabled={selectManagedLogoAjax}
			data-test="managed-org-btn"
		>
			{#if selectManagedLogoAjax}
				<SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
			{:else}
				<span class="managed-rounded-square-lg">
					<img
						src={DEFAULT_MANAGED_LOGO}
						alt="managed"
						class="mt-0 w-9 max-h-[36px] object-contain"
					/>
				</span>
				<span class="ml-4">
					{$_('This is an organization account')}
				</span>
			{/if}
		</button>
		<button
			on:click={selectManagedSelf}
			class="btn-background text-left px-4 h-18 text-lg inline-flex items-center"
			data-test="managed-personally-btn"
			disabled={selectManagedSelfAjax}
		>
			{#if selectManagedSelfAjax}
				<SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
			{:else}
				<span class="managed-rounded-square-lg bg-transparent flex items-center justify-center">
					<img
						src={DEFAULT_PERSONAL_LOGO}
						alt="managed"
						class="mt-0 w-9 max-h-[36px] object-contain invert"
					/>
				</span>
				<span class="ml-4">
					{$_('This is a personal account')}
				</span>
			{/if}
		</button>
	</div>
{/if}
<p class="text-sm flex items-start opacity-80">
	<InfoIcon css="w-4.5 h-4.5" />
	<!-- TBD i18n -->
	<span class="ml-2 italic"
		>Only admins of<br /><span class="px-indent not-italic">{managed?.domain}</span><br />can update
		this setting later</span
	>
</p>
